<template>
  <v-container>
    <list
      :title="title"
      :headers="headers"
      :data="data"
      :response="response"
      :loading="loading"
      @edit="editFn"
      @delete="deleteFn"
      @details="previewFn"
      @new="newFn"
      @reload="loadDocuments"
    />
    <delete-dialog
      :dialog="deleteDialog"
      :item="documentName"
      @agree="remove"
      v-show="false"
    />
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import List from "@/components/lists/BuilderList.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import snackbar from "@/utils/snackbar.js";
import categories from "@/utils/categories.js";
export default {
  components: {
    List,
    DeleteDialog,
  },
  data: () => ({
    headers: [],
    data: [],
    sort: "name",
    title: "Documents",
    deleteDialog: false,
    documentName: null,
    document: null,
    loading: true,
    response: null,
    categoryOptions: [],
  }),
  created() {
    this.setInitialData();
    this.categoryOptions = categories.categoryOptions();
    if (this.$route.query.page || this.$route.query.search) {
      this.loadDocuments(this.$route.query);
    } else {
      this.loadDocuments();
    }
  },
  methods: {
    setInitialData() {
      this.headers = [
        {
          text: "Document Title",
          align: "left",
          sortable: false,
          value: "title",
        },
        {
          text: "Visibility",
          align: "left",
          sortable: false,
          value: "crm_group.name",
        },
        {
          text: "Category",
          align: "left",
          sortable: false,
          value: "categories",
        },
        {
          text: "Type",
          align: "left",
          sortable: false,
          value: "file_resource.type",
        },
        { text: "", align: "right", value: "action", sortable: false },
      ];

      this.title = "Documents";
    },
    loadDocuments(params) {
      if (!params) {
        params = {};
      }
      this.loading = true;
      this.data = [];
      api.getDocumentList(params).then((resp) => {
        this.loading = false;
        this.response = resp;
        this.data = resp.results;
      });
    },
    editFn(item) {
      // this.$router.push({
      //   name: "documentsEdit",
      //   params: { id: item.uuid, action: "edit" },
      // });
    },
    deleteFn(item) {
      // this.deleteDialog = true;
      // this.document = item;
      // this.documentName = item.title;
    },

    remove(agreed) {
      // if (agreed) {
      //   api.deleteDocument(this.document.uuid).then((resp) => {
      //     snackbar.success("Successfully Deleted");
      //     this.loadDocuments(this.$route.query);
      //   });
      // }
      // this.deleteDialog = false;
    },
    previewFn(item) {
      // this.$router.push({
      //   name: "documentsDetails",
      //   params: { id: item.uuid, action: "preview" },
      // });
    },
    newFn(item) {
      // this.$router.push({
      //   name: "documentsAdd",
      //   params: { id: "-", action: "new" },
      // });
    },
  },
};
</script>
