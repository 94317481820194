<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      hide-default-footer
      :options.sync="options"
      :server-items-length="24"
      :items-per-page="24"
      class="elevation-1 text-overflow my-10"
      @click:row="previewItem"
    >
      <!-- style="cursor:pointer" -->
      <template v-slot:top>
        <v-toolbar flat height="70">
          <v-toolbar-title class="headline" :data-cy="title">
            <span>
              {{ title }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-form @submit.prevent="submitSearch">
            <v-text-field
              v-model="search"
              :label="'Search'"
              single-line
              hide-details
              color="primary"
              @click:append-outer="submitSearch"
              append-outer-icon="mdi-magnify"
            />
          </v-form>
          <v-spacer />
          <slot name="select-headers"></slot>
          <slot name="boatImport"></slot>
          <v-btn
            color="primary"
            dark
            text
            data-cy="builderAddNewBtn"
            @click="newItem()"
            v-if="canUserAddDelete && showAddNew"
            >Add New
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.is_boat_specific="{ item }">
        <v-icon class="mx-2" v-if="item.is_boat_specific">mdi-check</v-icon>
      </template>

      <!-- <template v-slot:item.model.brand.name="{ item }">
        <CopyContent :text="item.model.brand.name"> </CopyContent>
      </template> -->
      <template v-slot:item.vendors="{ item }">
        <v-chip
          v-bind:key="vendor.uuid"
          v-for="vendor in item.vendors"
          class="mx-1"
        >
          <span>{{ vendor.name }}</span>
        </v-chip>
      </template>

      <template v-slot:item.domains="{ item }">
        <v-chip v-bind:key="domain" v-for="domain in item.domains" class="mx-1">
          <span>{{ domain.toLowerCase() }}</span>
        </v-chip>
      </template>

      <template v-slot:item.country="{ item }">
        <span>{{ prettyCountryName(item.country) }}</span>
      </template>

      <template v-slot:item.categories="{ item }">
        <div v-if="item.file_resource.categories !== null">
          <v-chip
            :key="index"
            v-for="(category, index) in getCategories(
              item.file_resource.categories
            )"
            class="mx-1"
          >
            <span>{{ category }}</span>
          </v-chip>
        </div>
      </template>
      <template v-slot:item.brands="{ item }">
        <v-chip
          v-bind:key="brand.uuid"
          v-for="brand in item.brands"
          class="mx-1"
        >
          <span>{{ brand.name }}</span>
        </v-chip>
      </template>
      <template v-slot:item.prettified_categories="{ item }">
        <v-chip
          v-bind:key="index"
          v-for="(category, index) in item.prettified_categories"
          class="mx-1 ma-1"
        >
          <v-icon class="mr-2" v-if="category.icon">{{ category.icon }}</v-icon>
          <span :class="{ 'text-capitalize': !!category.icon }">{{
            category.category
          }}</span>
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <div
          style="height: 40px"
          class="pt-2"
          onclick="event.stopPropagation()"
        >
          <slot name="copyButton" v-bind:item="item"></slot>
          <v-icon
            v-if="isItemEditable(item)"
            class="mx-2 edit--hover"
            @click="editItem(item)"
            >mdi-pencil
          </v-icon>
          <v-icon
            class="mx-2 delete--hover"
            v-if="canUserDelete && canUserAddDelete && isItemDeletable(item)"
            @click="deleteItem(item)"
            >mdi-delete
          </v-icon>
          <!-- <v-icon small class="mx-2 preview--hover" @click="previewItem(item)"
        >mdi-file-find</v-icon
      > -->
        </div>
      </template>
      <template v-slot:item.users="{ item }">
        <span v-if="item.users">{{ item.users.length }}</span>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currentPage"
      :length="numberOfPages"
      :total-visible="7"
      color="primary"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constants from "@/utils/constants";

export default {
  // components: {
  //   CopyContent,
  // },
  components: {},

  props: {
    title: String,
    headers: Array,
    data: Array,
    sort: String,
    loading: Boolean,
    response: Object,
    showAddNew: {
      type: Boolean,
      default: true,
    },
    canUserDelete: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    helpDialog: { location: null, active: false },
    options: { sortBy: [], sortDesc: [], page: [], itemsPerPage: 24 },
    search: "",
    page: 1,
  }),

  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.search = this.$route.query.search;
      this.currentPage = Number(this.$route.query.page);
    }
  },

  watch: {
    options: {
      handler(value) {
        this.$emit("sort", value);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(["getMe"]),
    canUserAddDelete() {
      if (this.$route.name === "boats") {
        return this.getMe.menu_access.boats.write_groups.length !== 0;
      } else {
        return true;
      }
    },
    formTitle() {
      return this.title.substring(0, this.title.length - 1);
    },
    numberOfPages() {
      if (!this.response) {
        return 0;
      }
      return parseInt(Math.ceil(this.response.count / 24));
    },
    currentPage: {
      get: function() {
        return this.page;
      },
      set: function(value) {
        this.page = value;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let ordering = "";
        if (sortBy.length !== 0 && sortDesc.length !== 0) {
          ordering = `${sortDesc[0] === true ? "-" : ""}${sortBy[0]}`;
        }

        const orderingName =
          this.headers.find((item) => {
            return (
              typeof item.orderingName !== "undefined" &&
              sortBy[0] === item.value
            );
          }) || null;

        const getOrdering = () => {
          if (typeof this.$route.query.ordering === "undefined") {
            return orderingName === null
              ? ordering
              : `${sortDesc[0] === true ? "-" : ""}${
                  orderingName.orderingName
                }`;
          }
          return this.$route.query.ordering;
        };

        this.$emit("reload", {
          ...this.$route.query,
          page: value,
          ordering: getOrdering(),
        });

        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: this.page,
            ordering: getOrdering(),
          },
        });
      },
    },
  },
  methods: {
    prettyCountryName(country) {
      const countries = constants.countries();
      const foundCountry = countries.find((element) => {
        return element.value === country || element.text === country;
      });

      if (typeof foundCountry === "undefined") {
        return;
      }

      return foundCountry.text;
    },
    isItemEditable(item) {
      if (this.$route.name === "items" || this.$route.name === "documents") {
        return item.is_editable;
      }

      return true;
    },
    isItemDeletable(item) {
      if (this.$route.name === "items" || this.$route.name === "documents") {
        return item.is_removable;
      }

      return true;
    },
    closeHelpDialog(event) {
      if (event === "close") {
        this.helpDialog.active = false;
      }
    },
    handleHelp(event) {
      this.helpDialog.active = true;
      if (this.$route.name === "dealers") {
        this.helpDialog.location = "account";
      } else {
        this.helpDialog.location = "";
      }
    },
    getCategories(categories) {
      let splitedCategories = [];
      if (categories.length > 5) {
        splitedCategories = categories.slice(0, 5);
        splitedCategories.push("...");
      } else {
        splitedCategories = categories;
      }
      return splitedCategories;
    },
    submitSearch(event) {
      this.page = 1;
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: this.page,
          search: this.search,
        },
      });
      this.$emit("reload", {
        ...this.$route.query,
        search: this.search,
        page: this.page,
      });
      //event.preventDefault();
      return false;
    },
    editItem(item) {
      this.$emit("edit", item);
    },
    deleteItem(item) {
      this.$emit("delete", item);
    },
    previewItem(item) {
      this.$emit("details", item);
    },
    newItem() {
      this.$emit("new", {});
    },
  },
};
</script>

<style lang="scss">
.delete--hover {
  &:hover {
    color: red;
  }
}

.preview--hover {
  &:hover {
    color: --secondary;
  }
}

.edit--hover {
  &:hover {
    color: --primary;
  }
}
.copy--hover {
  &:hover {
    color: --primary;
  }
}
</style>
