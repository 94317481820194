var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 text-overflow my-10",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"hide-default-footer":"","options":_vm.options,"server-items-length":24,"items-per-page":24},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.previewItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"70"}},[_c('v-toolbar-title',{staticClass:"headline",attrs:{"data-cy":_vm.title}},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-spacer'),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitSearch($event)}}},[_c('v-text-field',{attrs:{"label":'Search',"single-line":"","hide-details":"","color":"primary","append-outer-icon":"mdi-magnify"},on:{"click:append-outer":_vm.submitSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_vm._t("select-headers"),_vm._t("boatImport"),(_vm.canUserAddDelete && _vm.showAddNew)?_c('v-btn',{attrs:{"color":"primary","dark":"","text":"","data-cy":"builderAddNewBtn"},on:{"click":function($event){return _vm.newItem()}}},[_vm._v("Add New ")]):_vm._e()],2)]},proxy:true},{key:"item.is_boat_specific",fn:function(ref){
var item = ref.item;
return [(item.is_boat_specific)?_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.vendors",fn:function(ref){
var item = ref.item;
return _vm._l((item.vendors),function(vendor){return _c('v-chip',{key:vendor.uuid,staticClass:"mx-1"},[_c('span',[_vm._v(_vm._s(vendor.name))])])})}},{key:"item.domains",fn:function(ref){
var item = ref.item;
return _vm._l((item.domains),function(domain){return _c('v-chip',{key:domain,staticClass:"mx-1"},[_c('span',[_vm._v(_vm._s(domain.toLowerCase()))])])})}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.prettyCountryName(item.country)))])]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [(item.file_resource.categories !== null)?_c('div',_vm._l((_vm.getCategories(
            item.file_resource.categories
          )),function(category,index){return _c('v-chip',{key:index,staticClass:"mx-1"},[_c('span',[_vm._v(_vm._s(category))])])}),1):_vm._e()]}},{key:"item.brands",fn:function(ref){
          var item = ref.item;
return _vm._l((item.brands),function(brand){return _c('v-chip',{key:brand.uuid,staticClass:"mx-1"},[_c('span',[_vm._v(_vm._s(brand.name))])])})}},{key:"item.prettified_categories",fn:function(ref){
          var item = ref.item;
return _vm._l((item.prettified_categories),function(category,index){return _c('v-chip',{key:index,staticClass:"mx-1 ma-1"},[(category.icon)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(category.icon))]):_vm._e(),_c('span',{class:{ 'text-capitalize': !!category.icon }},[_vm._v(_vm._s(category.category))])],1)})}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"pt-2",staticStyle:{"height":"40px"},attrs:{"onclick":"event.stopPropagation()"}},[_vm._t("copyButton",null,{"item":item}),(_vm.isItemEditable(item))?_c('v-icon',{staticClass:"mx-2 edit--hover",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil ")]):_vm._e(),(_vm.canUserDelete && _vm.canUserAddDelete && _vm.isItemDeletable(item))?_c('v-icon',{staticClass:"mx-2 delete--hover",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete ")]):_vm._e()],2)]}},{key:"item.users",fn:function(ref){
          var item = ref.item;
return [(item.users)?_c('span',[_vm._v(_vm._s(item.users.length))]):_vm._e()]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":7,"color":"primary"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }