var categoryOptions = () => {
  return [
    {
      category: "electrical systems",
      icon: "mdi-flash",
    },
    {
      category: "engine",
      icon: "mdi-engine",
    },
    {
      category: "security",
      icon: "mdi-lock",
    },
    {
      category: "general",
      icon: "mdi-card-text",
    },
    {
      category: "navigation",
      icon: "mdi-compass",
    },
    {
      category: "entertainment",
      icon: "mdi-bullseye-arrow",
    },
    {
      category: "bilge",
      icon: "mdi-water-pump",
    },
    {
      category: "battery",
      icon: "mdi-battery-80",
    },
    {
      category: "display",
      icon: "mdi-monitor",
    },
    {
      category: "sails",
      icon: "mdi-sail-boat",
    },
  ];
};

export default { categoryOptions };
